import { socialMedias } from '@/config';
import { Container, Stack, Typography } from '@mui/material';
import { EmptyMobileStyles as Styled } from './styles';

export const EmptyMobile = () => {
  return (
    <Styled.Container>
      <Styled.LogoWrapper>
        <img width="85" height="70" src="/assets/logo.png" alt="Crowns" />
        <img width="135" height="49" src="/assets/logo-text.svg" alt="Crowns" />
      </Styled.LogoWrapper>

      <Container maxWidth="xs">
        <Typography component="h2" variant="h4" lineHeight="2">
          Desktop only
        </Typography>
        <Typography>
          The Crowns app is not supported on mobile devices, please switch to
          desktop browser.
        </Typography>
      </Container>

      <Stack direction="row" spacing={3}>
        {socialMedias.map(({ name, href, icon: Icon }) => (
          <Styled.Link key={name} aria-label={name} href={href} target="_blank">
            <Icon size={40} />
          </Styled.Link>
        ))}
      </Stack>
    </Styled.Container>
  );
};
