import { Crown } from '@/models';
import { useAppDispatch, useAppSelector } from '@/store';
import { selectNftState, setNfts, setSelectedNft } from './nft-slice';

export const useNftStore = () => {
  const dispatch = useAppDispatch();
  const { nfts, selectedNft } = useAppSelector(selectNftState);

  const setSelectedCrown = (crown: Crown) => {
    dispatch(setSelectedNft(crown));
  };

  const setCrowns = (crowns: Crown[]) => {
    dispatch(setNfts(crowns));
  };

  return {
    nfts,
    selectedNft,
    setSelectedCrown,
    setCrowns,
  };
};
