import { FaDiscord } from '@react-icons/all-files/fa/FaDiscord';
import { FaGithub } from '@react-icons/all-files/fa/FaGithub';
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter';

export const Paths = {
  All: '*',
  Home: '/',
  Crown: '/crowns/:crownId',
};

export const Routes = {
  Home: '/',
  Crown: (id: number) => `/crown/${id}`,
  Blog: 'https://medium.com/cap-ois',
  Twitter: 'https://twitter.com/cap_ois',
  Discord: 'https://discord.gg/yVEcEzmrgm',
};

export const Errors = {
  PlugNotConnected: 'Plug is not connected',
};

export const socialMedias = [
  {
    name: 'discord',
    href: 'https://discord.gg/yVEcEzmrgm',
    icon: FaDiscord,
  },
  {
    name: 'twitter',
    href: 'https://twitter.com/cap_ois',
    icon: FaTwitter,
  },
  {
    name: 'github',
    href: 'https://github.com/psychedelic',
    icon: FaGithub,
  },
];
