import { RaffleTicketStatus } from '@/store';

export const HASHTAGS = 'Crowns,InternetComputer';

export const TWEET_TEXT = {
  [RaffleTicketStatus.NotEntered]:
    'The%20CAP%20Crowns%20NFT%20raffle%20is%20live%21%21%20%F0%9F%91%91%F0%9F%91%91%F0%9F%91%91%0D%0A%0D%0AJoin%20me%20in%20the%20free%20raffle%20and%20get%20a%20chance%20to%20win%20one%20of%20the%2010%2C000%20Crowns%20by%20%40cap_ois%20%F0%9F%94%A5%F0%9F%94%A5%0A%0D%0A',
  [RaffleTicketStatus.Entered]:
    'I%20just%20entered%20the%20CAP%20Crowns%20NFT%20raffle%20%F0%9F%A5%B3%F0%9F%A5%B3%F0%9F%A5%B3%0D%0A%0D%0AJoin%20me%20in%20the%20free%20raffle%20and%20get%20a%20chance%20to%20win%20one%20of%20the%2010%2C000%20NFT%20Crowns%20by%20%40cap_ois%20%F0%9F%91%91%F0%9F%94%A5%0D%0A%0D%0A',
  [RaffleTicketStatus.Won]:
    'I%20won%20one%20of%20the%2010%2C000%20CAP%20Crowns%21%21%20%F0%9F%A4%B4%F0%9F%91%B8%F0%9F%94%A5%0D%0A%0D%0AOne%20of%20the%20first%20NFTs%20on%20the%20IC%20using%20%40cap_ois%20to%20have%20full%20provenance%20%F0%9F%91%91%F0%9F%91%91%F0%9F%91%91%0D%0A%0D%0A',
};
